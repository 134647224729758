/* eslint-disable prettier/prettier  */
// React
import React from "react";
import PropTypes from "prop-types";
 
// Components
import {
  SEGMENT as TEXT_SEGMENT,
  getNormalizedSegment,
} from "@gcloud-npm/utils.enums.segments";
import getColor, {
  COLOR_DICTIONARY as TEXT_COLOR,
  OPACITY as TEXT_OPACITY,
} from "@gcloud-npm/utils.enums.colors";
 
// Utils
import { extendTextInfoClassNames, getProperKind } from "./utils";
 
// Enums
import {
  TEXT_ALIGN,
  TEXT_BORDER,
  TEXT_DECORATION,
  TEXT_DISPLAY,
  TEXT_WRAP,
  TEXT_FONT_WEIGHT,
  TEXT_HEADING_LEVEL,
  TEXT_KIND,
  TEXT_SIZE,
} from "./enums";
 
export {
  TEXT_ALIGN,
  TEXT_BORDER,
  TEXT_COLOR,
  TEXT_OPACITY,
  TEXT_DECORATION,
  TEXT_DISPLAY,
  TEXT_WRAP,
  TEXT_FONT_WEIGHT,
  TEXT_HEADING_LEVEL,
  TEXT_KIND,
  TEXT_SEGMENT,
  TEXT_SIZE,
};
 
const Text = ({ component, ...restProps }) => {
  const Component = component;
  return <Component {...restProps} />;
};
 
const COMPONENT_NAME = "TextInfo";
  /**
   * Componente que se utiliza para renderizar texto con distintos estilos
   */
const TextInfo = (props) => {
  const {
    className = "",
    segment: segmentProp,
    kind,
    text,
    color,
    opacity,
    bgOpacity,
    backgroundColor,
    level,
    cms,
    cmsHandler=null,
    cyData,
    decoration,
    display,
    sizeLegacy,
    title,
    onMouseEnter,
    onMouseOver,
    onMouseDown,
    onMouseUp,
    onMouseLeave,
    ...restProps
  } = props;
 
  const normalizedKind = getProperKind(kind, text);
 
  // smthng
  if (!normalizedKind) {
    return null;
  }
 
  const segment = getNormalizedSegment(segmentProp);
  const colorOpacity = getColor(color, opacity, segment);
  const bgColorOpacity =
    backgroundColor && backgroundColor !== "transparent"
      ? getColor(backgroundColor, bgOpacity, segment)
      : "transparent";
  const extendedClassNames = extendTextInfoClassNames({
    segment,
    kind: normalizedKind,
    decoration,
    sizeLegacy,
    ...restProps,
  });
 
  const displayClassName = `text-display--${display}`;
 
  const componentClassName = `text${
    className ? ` ${className}` : ""
  } text-color--${segment}--${color.toLowerCase()}${extendedClassNames}${
    cmsHandler ? " element-editable" : ""
  } ${displayClassName}`;

  // cms placeholder
  if (cms && text === "") {
    if (cmsHandler) {
      return (
        <Text
          component='span'
          className={`${componentClassName} cms-text-placeholder`}
          onClick={
            cms && cmsHandler
              ? () =>
                  cmsHandler({
                    ...props,
                    name: "text",
                  })
              : null
          }
          dangerouslySetInnerHTML={{ __html: 'Texto' }}
        />
      );
    } else return null;
  }
 
  return (
    <>
      <Text
        component={
          normalizedKind === TEXT_KIND.HEADING
            ? `${normalizedKind}${level}`
            : normalizedKind
        }
        title={title}
        className={componentClassName}
        dangerouslySetInnerHTML={{ __html: text }}
        onClick={ cms && cmsHandler ? () => cmsHandler({
          ...props,
          name: 'text'
        }): null }
        data-cy={cyData}
        style={{ color: colorOpacity, backgroundColor: bgColorOpacity }}
        onMouseEnter={onMouseEnter}
        onMouseOver={onMouseOver}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onMouseLeave={onMouseLeave}
      />
      <style jsx global>{`.text {
  &-size,
  &-size_legacy {
    &--t1,
    &--title01 {
      // font-size: clamp(24px, 8.33vw, 32px);
      font-size: clamp(30px, 6.4vw, 32px);

      @media screen and (min-width: 768px) {
        font-size: clamp(28px, 3.32vw, 34px);
        letter-spacing: -1px;
        word-spacing: 2px;
        line-height: 100%;
      }

      // desktop
      @media screen and (min-width: 1024px) {
        font-size: clamp(28px, 2.52vw, 40px);
        line-height: 100%;
        letter-spacing: -1px;
        word-spacing: 3px;
      }
    }

    &--t2,
    &--title02,
    &--title015 {
      font-size: clamp(27px, 6vw, 30px);
      letter-spacing: -0.75px;

      @media screen and (min-width: 768px) {
        letter-spacing: -0.5px;
        font-size: clamp(26px, 2.92vw, 28px);
      }

      // desktop
      @media screen and (min-width: 1024px) {
        font-size: clamp(26px, 2.228vw, 36px);
        letter-spacing: -0.5px;
        word-spacing: 3px;
      }
    }

    &--t3,
    &--title03 {
      font-size: clamp(26px, 5.6vw, 28px);

      @media screen and (min-width: 768px) {
        font-size: clamp(24px, 2.74vw, 26px);

        letter-spacing: -0.5px;
        word-spacing: 2px;
      }

      // desktop
      @media screen and (min-width: 1024px) {
        font-size: clamp(24px, 1.96vw, 32px);
        letter-spacing: -0.5px;
      }
    }

    &--t4,
    &--title04 {
      font-size: clamp(24px, 4.8vw, 26px);

      @media screen and (min-width: 768px) {
        font-size: clamp(22px, 2.52vw, 24px);
      }

      @media screen and (min-width: 1024px) {
        font-size: clamp(22px, 1.73vw, 26px);
      }
    }

    &--t5,
    &--title045 {
      font-size: clamp(22px, 4.4vw, 23px);
      letter-spacing: -0.75px;

      @media screen and (min-width: 768px) {
        font-size: clamp(20px, 2.222vw, 21px);
      }

      @media screen and (min-width: 1024px) {
        font-size: clamp(20px, 1.466vw, 22px);
      }
    }

    &--t6,
    &--title05 {
      font-size: clamp(20px, 4.4vw, 22px);

      @media screen and (min-width: 768px) {
        font-size: clamp(19px, 2.222vw, 20px);
      }

      @media screen and (min-width: 1024px) {
        font-size: clamp(19px, 1.4vw, 20px);
      }
    }

    &--t7,
    &--title035,
    &--title06 {
      font-size: clamp(18px, 4vw, 20px);
      letter-spacing: -0.5px;
      line-height: 130% !important;

      @media screen and (min-width: 768px) {
        font-size: clamp(18px, 2.1vw, 19px);
      }

      @media screen and (min-width: 1024px) {
        font-size: clamp(18px, 1.26vw, 19px);
      }
    }

    &--body1,
    &--caption01 {
      line-height: 140% !important;
      font-size: 17px;

      & > * {
        line-height: 140% !important;
        font-size: clamp(16px, 4.25vw, 17px);
      }

      @media screen and (min-width: 768px) {
        line-height: 140% !important;
        font-size: 16px;

        & > * {
          line-height: 140% !important;
          font-size: 16px;
        }
      }
    }

    &--body2,
    &--caption02 {
      font-size: 16px;
      line-height: 140% !important;

      @media screen and (min-width: 768px) {
        font-size: 15px;
      }

      @media screen and (min-width: 1024px) {
        font-size: 15px;
      }
    }

    &--subtitle1 {
      line-height: 140% !important;
      font-size: 17px;
      letter-spacing: -0.3px;

      & > * {
        line-height: 140% !important;
        font-size: 17px;
      }

      @media screen and (min-width: 768px) {
        line-height: 140% !important;
        font-size: 17px;

        & > * {
          line-height: 140% !important;
          font-size: 17px;
        }
      }
    }

    // caption02 -> de 15 a 14 (me mola...)
    &--subtitle2 {
      font-size: 15px;
      line-height: 150%;

      @media screen and (min-width: 768px) {
        font-size: 16px;
      }

      // desktop
      @media screen and (min-width: 1024px) {
        font-size: clamp(15px, 1.33vw, 16px);
      }
    }

    &--caption,
    &--caption03 {
      font-size: 15px;

      @media screen and (min-width: 768px) {
        font-size: 14px;
      }

      // 12 me parece excesivamente pequeño...
      @media screen and (min-width: 1024px) {
        font-size: 14px;
      }
    }
  }

  &-size--price {
    &-xxl {
      font-size: clamp(40px, 12.5vw, 50px);

      @media screen and (min-width: 768px) {
        font-size: clamp(48px, 6.22vw, 56px);
      }

      @media screen and (min-width: 1024px) {
        font-size: clamp(48px, 4.66vw, 56px);
      }
    }

    &-xl {
      font-size: 36px;

      @media screen and (min-width: 768px) {
        font-size: clamp(32px, 3.51vw, 36px);
      }

      @media screen and (min-width: 1024px) {
        font-size: clamp(32px, 3.166vw, 38px);
      }
    }

    &-l,
    &-m_l {
      font-size: clamp(28px, 9.14vw, 32px);

      @media screen and (min-width: 768px) {
        font-size: 30px;
      }

      @media screen and (min-width: 1024px) {
      }
    }

    &-m {
      font-size: 28px;
    }

    &-s {
      font-size: 24px;

      @media screen and (min-width: 768px) {
        font-size: 26px;
      }

      @media screen and (min-width: 1024px) {
        font-size: 26px;
      }
    }

    &-xs {
      font-size: 18px;
    }
  }

  :global(a){
    color: #0079BB
  }
}

.text {
  overflow-wrap: break-word;
  text-wrap: pretty;
  &-gp {
    /* FONTS */
    &--font-regular {
      font-family: Regular;
      font-weight: 400;
    }
    &--font-light {
      font-family: Light;
      font-weight: 300;
    }
    &--font-bold {
      font-family: Bold;
      font-weight: 400;
    }
    &--font-thin {
      font-family: Thin;
      font-weight: 200;
    }
    &--font-hand {
      font-family: Hand;
    }
  }
  &-emp {
    &--font-regular {
      font-family: RegularEmp;
      font-weight: 400;
    }
    &--font-light {
      font-family: LightEmp;
      font-weight: 300;
    }
    &--font-bold {
      font-family: BoldEmp;
      font-weight: 400;
    }
    &--font-thin {
      font-family: ThinEmp;
      font-weight: 200;
    }
    &--font-hand {
      font-family: HandEmp;
    }
  }
  &-plus {
    &--font-regular {
      font-family: RegularPlus;
      font-weight: 500;
    }
    &--font-light {
      font-family: LightPlus;
      font-weight: 500;
    }
    &--font-bold {
      font-family: BoldPlus;
      font-weight: 500;
    }
  }

  /* ALIGNMENTS */
  &-align {
    &--center {
      text-align: center;
    }
    &--left {
      text-align: left;
    }
    &--right {
      text-align: right;
    }
    &--justify {
      text-align: justify;
    }
  }

  /* DISPLAY */
  &-display {
    &--inline {
      display: inline;
    }
    &--block {
      display: block;
    }
    &--none {
      display: none;
    }
  }

  /* WRAP */
  &-wrap {
    &--pretty {
      text-wrap: pretty;
    }
    &--balance {
      text-wrap: balance;
    }
    &--wrap {
      text-wrap: wrap;
    }
    &--nowrap {
      text-wrap: nowrap;
    }
  }

  /* BORDER */
  &-border--rounded {
    border-radius: 3.125rem;
    padding: 0.25rem 0.75rem;
  }

  /* DECORATION */
  &-decoration {
    &--underline {
      text-decoration: underline;
    }
    &--line-through {
      text-decoration: line-through;
    }
  }
  * {
    line-height: 140%;
  }
  ol {
    list-style: auto;
    li {
      position: relative;
      left: 20px;
    }
    li[data-list="bullet"]{
      list-style: disc;
    }

  }
  ul{
    list-style: inside;
    li{
      position: relative;
    }
    li span {
      position: relative;
    }
  }


}
`}</style>
    </>
  );
};
 
TextInfo.propTypes = {
  /**
   * {
   *  "info": "Texto que se mostrará en el componente. Puede guardar haciendo control+s",
   *  "kind": "both",
   *  "beautifulName": "Texto"
   * }
   */
  text: PropTypes.string.isRequired,
 
  /**
   * {
   *  "info": "Texto que se mostrará al dejar el cursor sobre el componente (tooltip/title).",
   *  "kind": "both",
   *  "beautifulName": "Título"
   * }
   */
  title: PropTypes.string,
 
  /**
   * {
   *  "info": "Tipo de texto, define si el componente es un heading, paragraph o span.",
   *  "kind": "dev",
   *  "beautifulName": "Tipo",
   *  "values": "./enums.js:TEXT_KIND"
   * }
   */
  kind: PropTypes.string,
 
  /**
   * {
   *  "info": "Nivel del heading (h1, h2, h3, etc.), si el componente es un heading.",
   *  "kind": "dev",
   *  "beautifulName": "Nivel de Header",
   *  "values": "./enums.js:TEXT_HEADING_LEVEL"
   * }
   */
  level: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
 
  /**
   * {
   *  "info": "Clase CSS adicional para aplicar estilos personalizados.",
   *  "kind": "dev",
   *  "beautifulName": "ClassName"
   * }
   */
  className: PropTypes.string,
 
  /**
   * {
   *  "info": "Color del texto.",
   *  "kind": "both",
   *  "beautifulName": "Color"
   * }
   */
  color: PropTypes.string,
 
  /**
   * {
   *  "info": "Tamaño del texto, puede ser 'Title', 'Display' o 'Caption' (cada uno con sus niveles).",
   *  "kind": "both",
   *  "beautifulName": "Tamaño",
   *  "values": "./enums.js:TEXT_SIZE"
   * }
   */
  size: PropTypes.string,
 
  /**
   * {
   *  "info": "Fuente del texto, puede ser 'font-regular', 'font-light' o 'font-bold'.",
   *  "kind": "both",
   *  "beautifulName": "Fuente",
   *  "values": "./enums.js:TEXT_FONT_WEIGHT"
   * }
   */
  fontType: PropTypes.string,
 
  /**
   * {
   *  "info": "Alineación del texto: 'center', 'left', 'right', 'justify'.",
   *  "kind": "both",
   *  "beautifulName": "Alineación",
   *  "values": "./enums.js:TEXT_ALIGN"
   * }
   */
  align: PropTypes.string,
 
  /**
   * {
   *  "info": "Especifica si el texto se renderiza como block o inline.",
   *  "kind": "dev",
   *  "beautifulName": "Display",
   *  "values": "./enums.js:TEXT_DISPLAY"
   * }
   */
  display: PropTypes.string,
 
  /**
   * {
   *  "info": "Controla el comportamiento del wrapping del texto. Valores posibles: 'pretty', 'balance', 'wrap', 'nowrap'.",
   *  "kind": "both",
   *  "beautifulName": "Wrap",
   *  "values": "./enums.js:TEXT_WRAP"
   * }
   */
  wrap: PropTypes.string,
 
  /**
   * {
   *  "info": "Decoración del texto, como subrayado o línea cruzada.",
   *  "kind": "dev",
   *  "beautifulName": "Decoración",
   *  "values": "./enums.js:TEXT_DECORATION"
   * }
   */
  decoration: PropTypes.string,
 
  /**
   * {
   *  "info": "Borde del texto.",
   *  "kind": "dev",
   *  "beautifulName": "Borde",
   *  "values": "./enums.js:TEXT_BORDER"
   * }
   */
  border: PropTypes.string,
 
  /**
   * {
   *  "info": "Color de fondo del texto.",
   *  "kind": "dev",
   *  "beautifulName": "Color del Fondo"
   * }
   */
  backgroundColor: PropTypes.string,  /**
  * {
  *  "info": "Objeto con información sobre las propiedades necesarias (id, timestamp...) para editar los valores en orion.",
  *  "kind": "dev",
  *  "beautifulName": "CMS"
  * }
  */
  cms: PropTypes.object,
  /**
   * {
   *  "info": "Opacidad del texto.",
   *  "kind": "dev",
   *  "beautifulName": "Opacidad"
   * }
   */
  opacity: PropTypes.string,
 
  /**
   * {
   *  "info": "Opacidad del fondo del texto.",
   *  "kind": "dev",
   *  "beautifulName": "Opacidad del fondo"
   * }
   */
  bgOpacity: PropTypes.string, 
  /**
   * {
   *  "info": "Datos de prueba para Cypress.",
   *  "kind": "dev",
   *  "beautifulName": "cyData"
   * }
   */
  cyData: PropTypes.string,
 
  /**
   * {
   *  "info": "Activa el uso de tamaños legados para el texto.",
   *  "kind": "dev",
   *  "beautifulName": "sizeLegacy"
   * }
   */
  sizeLegacy: PropTypes.bool,
 
  /**
   * {
   *  "info": "Synthetic event onMouseEnter.",
   *  "kind": "dev",
   *  "beautifulName": "onMouseEnter"
   * }
   */
  onMouseEnter: PropTypes.func,
 
  /**
   * {
   *  "info": "Synthetic event onMouseOver.",
   *  "kind": "dev",
   *  "beautifulName": "onMouseOver"
   * }
   */
  onMouseOver: PropTypes.func,
 
  /**
   * {
   *  "info": "Synthetic event onMouseDown.",
   *  "kind": "dev",
   *  "beautifulName": "onMouseDown"
   * }
   */
  onMouseDown: PropTypes.func,
 
  /**
   * {
   *  "info": "Synthetic event onMouseUp.",
   *  "kind": "dev",
   *  "beautifulName": "onMouseUp"
   * }
   */
  onMouseUp: PropTypes.func,
 
  /**
   * {
   *  "info": "Synthetic event onMouseLeave.",
   *  "kind": "dev",
   *  "beautifulName": "onMouseLeave"
   * }
   */
  onMouseLeave: PropTypes.func,
};
 
 
TextInfo.defaultProps = {
  title: "",
  kind: TEXT_KIND.SPAN,
  level: TEXT_HEADING_LEVEL.H1,
  className: "",
  color: TEXT_COLOR.BLACK,
  size: TEXT_SIZE.CAPTION2,
  fontType: TEXT_FONT_WEIGHT.REGULAR,
  cms: null,
  align: TEXT_ALIGN.LEFT,
  border: TEXT_BORDER.NONE,
  decoration: TEXT_DECORATION.NONE,
  display: TEXT_DISPLAY.BLOCK,
  wrap: TEXT_WRAP.PRETTY,
  backgroundColor: TEXT_COLOR.TRANSPARENT,
  opacity: TEXT_OPACITY[100],
  bgOpacity: TEXT_OPACITY[100],
  sizeLegacy: false,
  cyData: "",
};
 
export default TextInfo;