/* eslint-disable import/extensions   */
import React from "react";
import PropTypes from "prop-types";
import CustomLink from "@/bit/components/components.custom-link";
import Spin from "./spin";
import {
  setLabelAnimation,
  setIconPositionCss,
  composeCssClassNamesAndStyles,
} from "./tools";

import {
  BUTTON_HEIGHT,
  BUTTON_WIDTH,
  BUTTON_TYPE,
  BUTTON_TYPE_HTML,
  BUTTON_TARGET,
  BUTTON_FONT_FAMILY,
  BUTTON_OPERATION,
  ICON_POSITION,
} from "./enums.js";

export {
  BUTTON_HEIGHT,
  BUTTON_WIDTH,
  BUTTON_TYPE,
  BUTTON_TARGET,
  BUTTON_TYPE_HTML,
  BUTTON_FONT_FAMILY,
  BUTTON_OPERATION,
  ICON_POSITION,
};
/**
 * Este componente renderiza un botón con diferentes estilos y contenido dependiendo de las propiedades proporcionadas
 */
const Button = (props) => {
  const {
    type,
    disabled,
    pill,
    fontFamily,
    forceExternal,
    width,
    height,
    Icon,
    iconProps,
    label,
    href,
    anchor,
    operation,
    segment,
    taggingInfo,
    onClick,
    target,
    buttonTypeHtml,
    className,
    cyData,
    cms,
    cmsHandler = null,
  } = props;

  let finalPillBoolValue = pill && (pill === true || pill === "true") ? true : false


  const labelFont = type === "link" ? "subtitle2" : "subtitle1";

  if (onClick && !taggingInfo) {
    return null;
  }
  /* COMPLETING TAGGINGINFO IF NEEDED */
  if (onClick && taggingInfo) {
    if (!taggingInfo.componentContent) {
      // eslint-disable-next-line no-param-reassign
      taggingInfo.componentContent = label || "";
    }
    if (!taggingInfo.componentName) {
      // eslint-disable-next-line no-param-reassign
      taggingInfo.componentName = "Button";
    }
  }

  const { cssClassNames, buttonStyles } = composeCssClassNamesAndStyles({
    segment,
    height,
    fontFamily,
    type,
    width,
    pill: finalPillBoolValue,
    disabled,
    className,
    cmsHandler
  });
  let iconPositionCss = null;

  if (Icon && iconProps && iconProps.position) {
    iconPositionCss = setIconPositionCss(iconProps.position);
  }

  const operationIsVisible = operation && operation !== BUTTON_OPERATION.NO;
  const labelAnimationCss = setLabelAnimation(operation);

  if (!label  || label === "") {
    if (cms && cmsHandler) {
      return (
        <CustomLink
          className={cssClassNames}
          onClick={() =>
                  cmsHandler({
                    ...props,
                    name: "button",
                  })}
        >
          Botón
        </CustomLink>
      );
    } else return null;
  }
  
  return (
    <CustomLink
      taggingInfo={taggingInfo}
      href={!cmsHandler ? href : null}
      ariaLabel={label}
      onClick={cms && cmsHandler ?
        () => cmsHandler({
          ...props,
          name: 'button'
        }) : onClick}
      style={buttonStyles}
      buttonTypeHtml={buttonTypeHtml}
      forceExternal={forceExternal}
      buttonParent
      className={cssClassNames}
      target={target}
      disabled={disabled}
      cyData={cyData}
      anchor={anchor}
    >
      {!operationIsVisible &&
        Icon &&
        (iconProps.width || iconProps.height) &&
        iconProps.position && (
          <div
            className={`layout-icon ${iconPositionCss || iconProps.position}`}
            id="button-textWithIcon"
          >
            <span className="button-textArea">{label}</span>
            <Icon {...iconProps} className="button-iconArea" />
          </div>
        )}
      {!operationIsVisible && !Icon && (
        <span id="button-textWithoutIcon" className={`text-size--${labelFont}`}>
          {label}
        </span>
      )}
      {operationIsVisible && (
        <div className={`${labelAnimationCss}`} id="button-operation">
          <Spin operation={operation} spinType={type} />
        </div>
      )}
      <style jsx global>{`.buttonx {
  position: relative;
  display: inline-flex;
  padding: 0rem 0.5rem;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 0.25rem;
  overflow: hidden;
  border-width: 1.5px;
  border-style: solid;
  outline: none;

  #button-textWithoutIcon {
    text-wrap: nowrap;
    whitespace: nowrap;
    overflow: hidden;
    white-space: nowrap;
  }

  .hover-layer {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.05);
    transition: all 0.3s;

    .buttonx.button-type--link & {
      background-color: yellow;
      // Otros estilos específicos
    }
  }
  &:hover {
    .hover-layer {
      transition: all 0.3s;
      opacity: 1;
    }
  }
  &:focus,
  &:active {
    outline: 1px solid #86888c;
  }
  &.status {
    &--disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }
  &.width {
    &--default {
      min-width: 104px;
    }
    &--short {
      min-width: 80px;
    }
    &--medium {
      min-width: 178px;
    }
    &--double {
      min-width: 240px;
    }
    &--large {
      min-width: 356px;
    }
    &--liquid {
      min-width: 0px;
    }
    &--full {
      width: 100%;
    }
  }
  &.height {
    &--normal {
      height: 3rem;

      &.pill-shape {
        border-radius: 1.25rem;
      }
    }
    &--small {
      height: 2rem;

      line-height: 1.25rem;
      &.pill-shape {
        border-radius: 1.25rem;
      }
    }
  }

  &.font-family {
    &-gp {
      &--regular {
        font-family: Regular;
      }
      &--bold {
        font-family: Bold;
      }
      &--light {
        font-family: Light;
      }
    }
    &-emp {
      &--regular {
        font-family: RegularEmp;
      }
      &--bold {
        font-family: BoldEmp;
      }
      &--light {
        font-family: LightEmp;
      }
    }
  }

  .layout-icon {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.icon--left {
      span {
        order: 2;
      }
      .svg-layout-size {
        order: 1;
        margin-right: 0.35rem;
      }
    }
    &.icon--right {
      span {
        order: 1;
      }
      .svg-layout-size {
        order: 2;
        margin-left: 0.35rem;
      }
    }
  }
}

.buttonx.button-type--link {
  &:hover {
    text-decoration: underline;
    color: #80cef9 !important;
  }
  .hover-layer {
    opacity: 0;
    background-color: #fff;
  }
}
`}</style>
    </CustomLink>
  );
};

Button.propTypes = {
  /**
   * {
   *  "info": "Información para el tagueado del componente.",
   *  "kind": "dev",
   *  "beautifulName": "Información de tagueado"
   * }
   */
  taggingInfo: PropTypes.object.isRequired,
  /**
   * {
   *  "info": "Establece tipo de botón a dibujar",
   *  "kind": "both",
   *  "beautifulName": "Tema del botón",
   *  "values": "./enums.js:BUTTON_TYPE"
   * }
   */
  type: PropTypes.string,
  /**
   * {
   *  "info": "Añade la clase css 'pill-shape' para que el botón posea 'border-radius",
   *  "kind": "both",
   *  "beautifulName": "Tipo Pill"
   * }
   */
  pill: PropTypes.bool,
  /**
   * {
   *  "info": "Permite definir si el botón está desactivado o no; en caso de desactivado el elemento cambiará de color y dejará de ser clicable",
   *  "kind": "dev",
   *  "beautifulName": "Habilitado/deshabilitado"
   * }
   */
  disabled: PropTypes.bool,
  /**
   * {
   *  "info": "Tipo de fuente del texto, por defecto es regular",
   *  "kind": "both",
   *  "beautifulName": "Tipo de fuente",
   *  "values":"./enums.js:BUTTON_FONT_FAMILY"
   * }
   */
  fontFamily: PropTypes.string,
  /**
   * {
   *  "info": "Función js que se ejecutará al hacer click",
   *  "kind": "dev",
   *  "beautifulName": "onClick"
   * }
   */
  onClick: PropTypes.func,
  /**
   * {
   *  "info": "Url a la que se accederá tras hacer click en el elemento",
   *  "kind": "both",
   *  "beautifulName": "Enlace"
   * }
   */
  href: PropTypes.string,
  /**
   * {
   *  "info": "Lugar donde se abrirá el enlace",
   *  "kind": "both",
   *  "beautifulName": "Href target"
   * }
   */
  target: PropTypes.string,
  /**
   * {
   *  "info": "Define si el enlace se tiene que forzar a comportar como enlace externo.",
   *  "kind": "dev",
   *  "beautifulName": "forceExternal",
   *  "values": "./enums.js:BUTTON_TARGET"
   * }
   */
  forceExternal: PropTypes.bool,
  /**
   * {
   *  "info": "Define el ancho del botón.",
   *  "kind": "both",
   *  "beautifulName": "Define el ancho del botón.",
   *  "values": "./enums.js:BUTTON_WIDTH"
   * }
   */
  width: PropTypes.string,
  /**
   * {
   *  "info": "Altura del botón.",
   *  "kind": "both",
   *  "beautifulName": "Altura del botón",
   *  "values": "./enums.js:BUTTON_HEIGHT"
   * }
   */
  height: PropTypes.string,
  /**
   * {
   *  "info": "Componente icono ya renderizado.",
   *  "kind": "dev",
   *  "beautifulName": "Altura del botón"
   * }
   */
  Icon: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  /**
   * {
   *  "info": "Propiedades que se heredarán al icono.",
   *  "kind": "dev",
   *  "beautifulName": "Propiedades de icono"
   * }
   */
  iconProps: PropTypes.object,
  /**
   * {
   *  "info": "Texto que aparecerá dentro del componente.",
   *  "kind": "both",
   *  "beautifulName": "Label"
   * }
   */
  label: PropTypes.string,
  /**
   * {
   *  "info": "Permite establecer una animación en función de un estado a este componente, reflejando una operación de las definidas BUTTON_OPERATION",
   *  "kind": "dev",
   *  "beautifulName": "Efecto de transición",
   *  "values": "./enums.js:BUTTON_OPERATION"
   * }
   */
  operation: PropTypes.string,
  /**
   * {
   *  "info": "Valor del type que cobrará el button en el DOM.",
   *  "kind": "dev",
   *  "beautifulName": "Type valor Dom",
   *  "values": "./enums.js:BUTTON_TYPE_HTML"
   * }
   */
  buttonTypeHtml: PropTypes.string,
  /**
   * {
   *  "info": "Classnames que heredará el componente, sirve para aplicar estilos",
   *  "kind": "dev",
   *  "beautifulName": "ClassName"
   * }
   */
  className: PropTypes.string,
  /**
   * {
   *  "info": "Cadena que identifica inequívocamente el componente, para finalidades de testing",
   *  "kind": "dev",
   *  "beautifulName": "cyData"
   * }
   */
  cyData: PropTypes.string,
  /**
   * {
   *  "info": "Objeto con información sobre las propiedades necesarias (id, timestamp...) para editar los valores en orion.",
   *  "kind": "dev",
   *  "beautifulName": "CMS"
   * }
   */
  cms: PropTypes.object,
  /**
   * {
   *  "info": "Handler para eventos del CMS. Se ejecuta cuando se clicka en el componente desde el CMS.",
   *  "kind": "dev",
   *  "beautifulName": "CMS Handler"
   * }
   */
  cmsHandler: PropTypes.func,
};

Button.defaultProps = {
  pill: false,
  type: BUTTON_TYPE.PRIMARY,
  className: "",
  disabled: false,
  fontFamily: BUTTON_FONT_FAMILY.REGULAR,
  width: "default",
  height: BUTTON_HEIGHT.NORMAL,
  Icon: null,
  iconProps: {
    position: ICON_POSITION.RIGHT,
  },
  label: "",
  operation: BUTTON_OPERATION.NO,
  onClick: null,
  target: "_self",
  buttonTypeHtml: BUTTON_TYPE_HTML.BUTTON,
  forceExternal: false,
  href: "",
  cyData: "",
  cmsHandler: null,
  cms: null
};
export default Button;
